import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import CompanyBanner from "../components/CompanyBanner";
import contact_banner from "../img/contact-us-banner.svg";
import $ from "jquery";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const Contact = () => {

    //For browse functionality start
    const [file, setFile] = useState(null);
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    //For browse functionality end

    //Browse functionality part 2 
    const [messageSent, setMessageSent] = useState(false);


    const [contact, setContact] = useState({
        name: "",
        email: "",
        mobile: "",
        company: "",
        message: ""
    })

    const [sending, setSending] = useState(false)

    //change handeler


    const changeHandeler = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value })

    }

    const sendMessage = () => {
        // Logic to send the message
        // On successful message send
        setMessageSent(true);
    };


    const submit = (e) => {
        e.preventDefault();

        try {
            setSending(true);

            const formData = new FormData();
            formData.append("name", contact.name);
            formData.append("email", contact.email);
            formData.append("phone", contact.phone);
            formData.append("company", contact.company);
            formData.append("message", contact.message);
            if (file) {
                formData.append("file", file);
            }

            $.ajax({
                method: "POST",
                url: `https://formsubmit.co/ajax/contact@infovertex.com`,
                dataType: "json",
                data: formData,
                processData: false,
                contentType: false,
                success: (data) => {
                    console.log("success");
                    setSending(false);
                    setMessageSent(true);
                    setContact({
                        name: "",
                        email: "",
                        mobile: "",
                        company: "",
                        message: ""
                    });
                    setFile(null);
                },
                error: (err) => {
                    console.log("error");
                    setSending(false);
                    setMessageSent(false);
                },
            });
        } catch (error) {
            console.log(error);
            setSending(false);
            setMessageSent(false);
        }
    };



    const title = "Career";
    const desc =
        "Send us your profile to explore new opportunities";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Layout>
            <CompanyBanner image={contact_banner} title={title} desc={desc} />

            <div className="why-acc">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 mt-4 mt-lg-0">
                            <div className="contact-card">
                                <div className="row">
                                    <form onSubmit={submit} >
                                        <div className="row">
                                            <div className="col-md-6 mt-2">
                                                <input
                                                    type="text"
                                                    required
                                                    name="name"
                                                    value={contact.name}
                                                    onChange={changeHandeler}
                                                    className="custom-field"
                                                    placeholder="Your Name *"
                                                />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <input
                                                    type="email"
                                                    required
                                                    name="email"
                                                    onChange={changeHandeler}
                                                    value={contact.email}
                                                    className="custom-field"
                                                    placeholder="Your Email *"
                                                />
                                            </div>
                                        </div>



                                        <div className="row mt-md-4">
                                            <div className="col-md-6 mt-2">
                                                <input
                                                    type="text"
                                                    required
                                                    name="mobile"
                                                    value={contact.mobile}
                                                    onChange={changeHandeler}
                                                    className="custom-field"
                                                    placeholder="Your Mobile *"
                                                />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <input
                                                    type="text"
                                                    required
                                                    name="company"
                                                    value={contact.company}
                                                    onChange={changeHandeler}
                                                    className="custom-field"
                                                    placeholder="Company Name *"
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-md-4">
                                            <div className="col-md-12 my-2">
                                                <textarea
                                                    className="custom-field"
                                                    name="message"
                                                    value={contact.message}
                                                    onChange={changeHandeler}
                                                    placeholder="Message"
                                                    rows="5"
                                                ></textarea>
                                            </div>

                                            <div className="row mt-md-4">
                                                <div className="col-md-6 mt-2">
                                                    <input
                                                        type="file"
                                                        onChange={handleFileChange}
                                                        className="custom-file-input"
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-md-12 text-center mt-5">

                                                <button className="service-btn" type="submit"
                                                    {
                                                    ...sending && { disabled: true }
                                                    }
                                                >
                                                    <BsArrowRight className="btn-icon" />

                                                    {
                                                        sending ? "Sending..." : "Send Message"
                                                    }
                                                </button>

                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Contact;
