import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import CompanyBanner from "../components/CompanyBanner";
import contact_banner from "../img/contact-us-banner.svg";
import $ from "jquery";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const Contact = () => {


  const [contact, setContact] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
    message:""
  })

  const [sending, setSending] = useState(false)

  //change handeler


  const changeHandeler = (e) => {
    setContact({...contact, [e.target.name]: e.target.value})

  }

  //submit 

  const submit = (e) => {
    e.preventDefault();
    console.log(contact)

    try {
      
      setSending(true)

      $.ajax({
        method: "POST",
        url: `https://formsubmit.co/ajax/contact@infovertex.com`,
        dataType: "json",
        accepts: "application/json",
        data: {
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          company: contact.company,
          message: contact.message,
        
        },
        success: (data) => {
          console.log("success");
          setSending(false)
          
        },
        error: (err) => {
          console.log("error");
        },
      });
    } catch (error) {
      console.log(error)
    }
  }

  const title = "Contact Us";
  const desc =
    "Let's collaborate to unlock the power of technology for your business.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <CompanyBanner image={contact_banner} title={title} desc={desc} />


      <div className="why-acc">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="deployment h-100">
                              <p className="white mb-2 mt-4">
                <h6>
                <br></br><br></br>
            <b>United States of America</b></h6>
                </p>
                <p className="white small">
                   6160 Warren Parkway Suite # 100
                <br />
                   Frisco, TX 75034
                </p>

                <p className="white mb-2 mt-5">
                <h6>
                                  <b>India</b>
                                  </h6></p>
                <p className="white small">
                 211, Maruti Titanium, S.P. Ring Road
                 
                  <br />
                 Nikol, Ahmedabad - 382350, Gujarat
                </p>

                <p className="white small email mt-4">
                  {" "}
                  <span>Email: </span> <a href="#">contact@infovertex.com</a>{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-8 mt-4 mt-lg-0">
              <div className="contact-card">
                <div className="row">
                  <form  onSubmit={submit} >
                  <div className="row">
                    <div className="col-md-6 mt-2">
                      <input
                        type="text"
                        required
                        name="name"
                        value={contact.name}
                        onChange={changeHandeler}
                        className="custom-field"
                        placeholder="Your Name *"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="email"
                        required
                        name="email"
                         onChange={changeHandeler}
                        value={contact.email}
                        className="custom-field"
                        placeholder="Your Email *"
                      />
                    </div>
                  </div>

                  

                  <div className="row mt-md-4">
                    <div className="col-md-6 mt-2">
                      <input
                        type="text"
                        required
                        name="mobile"
                        value={contact.mobile}
                        onChange={changeHandeler}
                        className="custom-field"
                        placeholder="Your Mobile *"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="text"
                        required
                        name="company"
                        value={contact.company}
                        onChange={changeHandeler}
                        className="custom-field"
                        placeholder="Company Name *"
                      />
                    </div>
                  </div>

                  <div className="row mt-md-4">
                    <div className="col-md-12 my-2">
                      <textarea
                        className="custom-field"
                        name="message"
                        value={contact.message}
                        onChange={changeHandeler}
                        placeholder="Message"
                        rows="5"
                      ></textarea>
                    </div>

                    <div className="col-md-12 text-center mt-5">

                      <button className="service-btn" type="submit"
                      {
                        ...sending && {disabled: true}
                      }
                      >
                      <BsArrowRight className="btn-icon" />
                         
                         {
                            sending ? "Sending..." : "Send Message"
                         }
                      </button>
                       
                    </div>
                  </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
