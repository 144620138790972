import dataAnalytics from "../img/data-analytics.jpg"
import business from "../img/business.jpg"
import ai from "../img/ai.jpg"
import devops from "../img/dev-ops.jpg"
import finance from "../img/finance.jpg"
import staffing from "../img/staffing-story.jpg"


export const stories = [
  {
    image: business,
    title: "Transforming Business Through Cloud Solutions",
    desc: "A global enterprise was struggling with scalability and efficiency. Our tailor-made cloud solutions empowered them to not only increase their scalability but also optimize costs, leading to improved business agility and success.",
  },
  {
    image: dataAnalytics,
    title: "Insightful Decision-making with Data & Analytics",
    desc: "A retail chain needed to harness the power of their data for strategic decision-making. Our Data & Analytics services turned their raw data into actionable insights, driving more effective marketing strategies and increased sales.",
  },
  {
    image:devops,
    title:"Seamless Integration with DevOps",
    desc:"A technology startup wanted to improve their software delivery process. Our DevOps solutions streamlined their development and operations, reducing the time-to-market and enhancing collaboration across teams."
  },
  {
    image:finance,
    title:"Financial Optimization with FinOPS",
    desc:"An e-commerce company was looking to manage and optimize their cloud financial operations. Our FinOPS services helped them achieve significant cost savings without sacrificing performance, aligning their spending with business value."

  },
  {
    image: ai,
    title:"Unlocking Potential with AI & Business Intelligence",
    desc:"A manufacturing firm needed to leverage Artificial Intelligence and Business Intelligence for predictive maintenance and strategic planning. Our solutions not only met but exceeded their expectations, ushering in a new era of efficiency and growth."
  },
  {
    image: staffing,
    title:"Flexible Staffing Solutions",
    desc:"Whether it was contingent, onshore, or offshore staffing, our solutions have consistently provided businesses with the right talent at the right time. From a healthcare provider needing specialized onshore staff to a software company expanding with offshore resources, our staffing solutions have always delivered."
  }
]